import React from 'react';

import config from '../../../config';
import ClosedSvg from '../images/closed';
import OpenedSvg from '../images/opened';
import Link from '../link';

const TreeNode = ({ className = '', url, title, items }) => {
  const hasChildren = items.length !== 0;

  let location = '';

  if (typeof document !== 'undefined') {
    location = document.location;
  }

  const pathName = config.gatsby.pathPrefix.slice(1) + url;
  const pageUrl = url && url.endsWith('introduction') ? config.gatsby.pathPrefix : url;

  const active = location && (location.pathname === pageUrl || location.pathname === pathName);
  const inSection = location && location.pathname.startsWith(pathName);
  const isCollapsed = !inSection;
  const calculatedClassName = `${className} item ${active ? 'active' : ''}`;

  return (
    <li className={calculatedClassName}>
      {title && (
        <Link to={pageUrl}>
          {title}
          {!config.sidebar.frontLine && title && hasChildren ? (
            <button aria-label="collapse" className="collapser">
              {!isCollapsed ? <OpenedSvg /> : <ClosedSvg />}
            </button>
          ) : null}
        </Link>
      )}

      {(!isCollapsed && hasChildren) || !title ? (
        <ul>
          {items.map((item, index) => (
            <TreeNode key={item.url + index.toString()} {...item} />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default TreeNode;
