import { LyftaThemeProvider } from '@lyfta/components-theme';
import * as React from 'react';

import Header from '../Header';
import { DocsStyles } from '../styles/GlobalStyles';
import { darkTheme, lightTheme } from './index';

class ThemeProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      isDarkThemeActive: false,
    };
  }

  componentDidMount() {
    this.retrieveActiveTheme();
  }

  retrieveActiveTheme = () => {
    const isDarkThemeActive = JSON.parse(window.localStorage.getItem('isDarkThemeActive'));

    this.setState({ isDarkThemeActive });
  };

  toggleActiveTheme = () => {
    this.setState((prevState) => ({ isDarkThemeActive: !prevState.isDarkThemeActive }));

    window.localStorage.setItem('isDarkThemeActive', JSON.stringify(!this.state.isDarkThemeActive));
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { children, location } = this.props;

    const { isDarkThemeActive } = this.state;

    const currentActiveTheme = isDarkThemeActive ? darkTheme : lightTheme;

    return (
      <div>
        <DocsStyles />
        <Header
          location={location}
          isDarkThemeActive={isDarkThemeActive}
          toggleActiveTheme={this.toggleActiveTheme}
        />
        <LyftaThemeProvider theme={currentActiveTheme}>{children}</LyftaThemeProvider>
      </div>
    );
  }
}

export default ThemeProvider;
