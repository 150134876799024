import isAbsoluteUrl from 'is-absolute-url';
import * as React from 'react';

const AnchorTag = ({ children: link, ...props }) => {
  if (link) {
    if (isAbsoluteUrl(props.href)) {
      return (
        <a href={props.href} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      );
    }
    return <a href={props.href}>{link}</a>;
  }
  return null;
};

export default AnchorTag;
