import get from 'lodash/get'

import config from './config'

const { REACT_APP_CI, REACT_APP_CI_BRANCH } = config

const CRA_PREFIX = 'REACT_APP_'

const currentBranch =
  REACT_APP_CI && REACT_APP_CI_BRANCH.startsWith('feature/')
    ? 'main'
    : REACT_APP_CI_BRANCH

const PREFIX =
  REACT_APP_CI === 'true'
    ? {
        main: `${CRA_PREFIX}STAGING_`,
        staging: `${CRA_PREFIX}STAGING_`,
        production: `${CRA_PREFIX}PRODUCTION_`,
      }[currentBranch] || CRA_PREFIX
    : CRA_PREFIX

/**
 * @param {string} env
 * @return {string}
 */
const getEnv = env => get(config, `${PREFIX}${env}`, '')

const apiHttps = getEnv('API_HTTPS') === 'true' ? 'https' : 'http'

export const appEnv = getEnv('ENV')
export const allowTrace = getEnv('TRACE')

export const version = getEnv('VERSION')
export const appName = getEnv('NAME')
export const isTeacherApp = appName === '@lyfta/web-teacher'
export const isAdminApp = appName === '@lyfta/web-admin'
export const appIdentifier = appName.replace(
  /@lyfta\/(web|native)-([a-z-]*)/g,
  '$2',
)

export const appDeployment =
  REACT_APP_CI === 'true'
    ? {
        main: 'dev',
        staging: 'test',
        production: null,
      }[currentBranch]
    : 'dev'

export const appBranch =
  REACT_APP_CI &&
  REACT_APP_CI_BRANCH.startsWith('feature/') &&
  REACT_APP_CI_BRANCH

export const googleAuthClientId = getEnv('GOOGLE_AUTH_CLIENT_ID')
export const googleTeacherAppSSO = getEnv('GOOGLE_TEACHER_APP_SSO')
export const microsoftAuthClientId = getEnv('MICROSOFT_AUTH_CLIENT_ID')
export const myloginAuthClientId = getEnv('MYLOGIN_CLIENT_ID')

const appRootSetting = getEnv('FOLDER_ROOT')
export const appRoot = appRootSetting
  ? `${appRootSetting}/${appIdentifier}/`
  : '/'

export const api = {
  baseUrl: `${apiHttps}://${getEnv('API_URL')}`,
  url: `${apiHttps}://${getEnv('API_URL')}/api/v1`,
}

export const appUrls = {
  student: (location, queryString = {}, path = '') => {
    const qs = Object.keys(queryString)
      .map(key => `${key}=${queryString[key]}`)
      .join('&')

    let url
    if (appRoot === '/') {
      url = `${location.protocol}//${location.hostname.replace(
        appIdentifier,
        'student',
      )}${location.port ? ':3002' : ''}/${path}`
    } else {
      url = `${location.protocol}//${location.hostname}${appRoot.replace(
        /[^/]+\/$/,
        'student/',
      )}${path}`
    }

    return qs ? `${url}?${qs}` : url
  },
  teacher: (location, queryString = {}, path = '') => {
    const qs = Object.keys(queryString)
      .map(key => `${key}=${queryString[key]}`)
      .join('&')

    const url = `${location.protocol}//${location.hostname.replace(
      appIdentifier,
      'teacher',
    )}${location.port ? ':3003' : ''}${appRoot.replace(
      appIdentifier,
      'teacher',
    )}`
    return qs ? `${url}?${qs}&${path ? `redirect=${path}` : ''}` : url
  },
  tutorial:
    'https://help.lyfta.com/en/collections/9630063-getting-started-with-lyfta ',
  bookWebinar: 'https://www.lyfta.com/training',
  signUp: (redirect = '') => {
    if (redirect === '' || redirect === '/') {
      // eslint-disable-next-line
      return appEnv === 'production'
        ? 'https://registration.lyfta.com/'
        : appEnv === 'staging'
        ? 'http://registration.dev.lyfta.com/'
        : 'http://localhost:3006/'
    }
    // eslint-disable-next-line
    return appEnv === 'production'
      ? `https://registration.lyfta.com?redirect=${redirect}`
      : appEnv === 'staging'
      ? `http://registration.dev.lyfta.com?redirect=${redirect}`
      : `http://localhost:3006?redirect=${redirect}`
  },
}

export const googleAPIKey = getEnv('GOOGLE_API_KEY')
export const googleAnalyticsParentId = getEnv('GA_PARENT_ID')
export const googleAnalyticsRegistrationId = getEnv('GA_REGISTRATION_ID')
export const googleAnalyticsStudentId = getEnv('GA_STUDENT_ID')
export const googleAnalyticsTeacherId = getEnv('GA_TEACHER_ID')
export const bingTracking = getEnv('BING')
export const bingTrackingRegistrationId = getEnv('BING_REGISTRATION_ID')
export const bingTrackingTeacherId = getEnv('BING_TEACHER_ID')
export const hubSpotAppId = getEnv('HUBSPOT_APP_ID')
export const intercomAppId = getEnv('INTERCOM_APP_ID')
export const intercomTourId = getEnv('INTERCOM_TOUR_ID')
export const intercomEnvironment = getEnv('ENV')
export const stripeKey = getEnv('STRIPE_KEY')
export const sentryAdminUrl = getEnv('SENTRY_ADMIN_URL')
export const sentryParentUrl = getEnv('SENTRY_PARENT_URL')
export const sentryStudentWebUrl = getEnv('SENTRY_STUDENT_WEB_URL')
export const sentryStudentAppUrl = getEnv('SENTRY_STUDENT_APP_URL')
export const sentryTeacherUrl = getEnv('SENTRY_TEACHER_URL')

export const useNewPlayerEnv = getEnv('USE_NEW_PLAYER') !== 'false'

export const storyWorldRoot = 'https://sw-cdn.lyfta.com/'
export const stripePaymentURL =
  'https://checkout.stripe.com/pay/cs_live_b1wi1j3IihO9OtkkMAaQWslg6V8OaVltbfkvcVum1SlmjMqkb0lAnTi9VL#fidkdWxOYHwnPyd1blppbHNgWk5xcFNfNzI1UndHQHJPQ2puVmBrPU5EVycpJ3VpbGtuQH11anZgYUxhJz8nMnZMPEcxMHxqNT19NzA3Z0xMJykncHFoRmphYHYnP34ncHFoVmpwd2ZgJz8nbXZaZHBxamhkcWxqaycpJ3BxaEZqa3Fga3EnPyc3NDw8NjE9PDcnKSdwcWhIYGFscGgnPydgaGRsaSd4eCUl'
export const unityUrl = getEnv('UNITY_URL')
export const ROCKET_LOADING_STORYWORLD_ID =
  getEnv('ROCKET_LOADING_STORYWORLD_ID') || ''

const getEnvInt = (key, defaultValue) => {
  const value = parseInt(getEnv(key), 10)
  if (Number.isNaN(value)) {
    return defaultValue
  }

  return value
}

export const SMALL_SCREEN_WIDTH = getEnvInt('SMALL_SCREEN_WIDTH', 480)
export const SMALL_SCREEN_HEIGHT = getEnvInt('SMALL_SCREEN_HEIGHT', 800)

function getStudentWebUrl() {
  if (typeof window === 'undefined' || !window?.location) {
    return ''
  }

  if (new URLSearchParams(window?.location?.search ?? '').get('forceUnity')) {
    return ''
  }

  let result = getEnv('STUDENT_WEB_URL')

  if (window?.location?.hostname) {
    result = result.replace('localhost', window.location.hostname)
  }

  return result
}

export const studentWebUrl = getStudentWebUrl()

export const prismicAccessToken = getEnv('PRISMIC_ACCESS_TOKEN')
export const prismicAPIEndpoint = getEnv('PRISMIC_API_ENDPOINT')

export const devtools = getEnv('DEVTOOLS')

export const LIMIT_CHILDREN = 3
