const config = {
  gatsby: {
    pathPrefix: process.env.GATSBY_PATH || '/',
    siteUrl: process.env.GATSBY_URL || 'https://docs.lyfta.com',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    title: 'Development Team',
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/introduction', // add trailing slash if enabled above
      '/dev-env',
      '/software-development',
      '/software-development',
      '/front-end',
      '/back-end',
      '/infrastructure',
    ],
    collapsedNav: [
      '/front-end',
      '/software-development',
      '/backend',
      '/dev-env',
      '/infrastructure',
      '/infrastructure/1-aws',
      '/infrastructure/2-heroku',
    ],
    links: [
      { text: 'Teacher App', link: 'https://teacher.lyfta.com' },
      { text: 'Student App', link: 'https://student.lyfta.com' },
      { text: 'Admin App', link: 'https://admin.lyfta.com' },
      { text: 'Lyfta@Home', link: 'https://home.lyfta.com' },
      { text: 'Registration App', link: 'https://registration.lyfta.com/' },
    ],
    frontline: false,
    ignoreIndex: true,
    title: 'Developer Documentation',
  },
  siteMetadata: {
    title: 'Developer Documentation | Lyfta',
    description: 'Documentation built with mdx.',
    ogImage: null,
    docsLocation: 'https://github.com/lyfta/lyfta-products/tree/main/docs/content',
    favicon: '/favicon.ico',
  },
  pwa: {
    enabled: true, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Lyfta Developer Documentation',
      short_name: 'LyftaDocs',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
}

module.exports = config
